import React, {useState} from "react";
import "../index.css";
import Header from '../../components/header';
import { Box, CircularProgress, Container, Typography } from "@material-ui/core";

const headerDetails = {
    title: 'Simple Weather Flutter App',
    img: '/weather.png',
    description: 'A simple flutter app built to display the current weather. You can preview what I am imagining here.',
}

export default function Home() {
    const [isLoading, setLoading] = useState(true);
    return (
    <Header details={headerDetails}>
      <div style={{textAlign: 'center'}}>
        <Typography variant="h3" style={{marginTop:'10px'}}>
            Simple Weather
        </Typography>

        {/* This is the iframe for the flutter app */}
        <div style={{marginTop:'20px'}}>
            <CircularProgress color="secondary" style={{display: isLoading ? '' : 'none'}} />
            <iframe
                src="https://flutter-simple-weather.vercel.app/#/"
                style={{height:'600px', width:'350px', display: isLoading ? 'none' : ''}}
                onLoad={() => {setLoading(false);}}
            />
        </div>

        {/* Description */}
        <Box style={{marginTop:'10px'}}>
            <Typography variant="h5">
                Description
            </Typography>
            <Container style={{maxWidth:'500px', textAlign: 'left'}}>
                <p style={{}}>
                    This is a simple flutter app envisioning how a simple weather application might look. The general idea is to display as much information as possible while showing as little as possible.
                </p>
                <p>
                    As mentioned above, it is built using flutter. Flutter is a framework that allows building apps for iOS, Android, Web, and desktop! It can deploy almost anywhere which is very neat. I haven't deployed to the different App Stores because deploying there can be hard. This is also missing the simple functionality of being able to select a place and show live data from there.
                </p>
                
            </Container>
        </Box>
      </div>
    </Header>
    );
  }